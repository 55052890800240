<template>
  <div>
    <!-- <img
      style="
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: block;
        width: 100%;
        z-index: -1;
      "
      src="../assets/image/homepage.jpg"
    /> -->
    <headPage />
<!-- 温度适宜 -->
    <div class="box1" style="overflow: hidden">
    </div>
    <!-- 毛细管辐射系统 -->
    <div class="box2" style="overflow: hidden">
        <div class="info2">
            <div class="title2">毛细管辐射系统</div>
            <div class="line"></div>
            <div class="title2-3">如同人体血液循环系统，毛细管系统的设计初衷是依照人体仿生学。通过水在极细毛细管内流动来交换能量，为房间制冷或供暖。为当代最高效、节能、环保的室内调温方式。</div>
             <div class="title2-4">这是一种比中央空调更优的新型节能舒适系统。<br>以水作为媒介，通过布置在室内顶面/墙面均匀紧密的毛细管网辐射传热，以控制管内水温进而达到调节室内温度，具有较好的制热制冷能力。<br>夏季供水温度为18-20°℃，冬季系统供水温度为28-30°C。</div>
        <img class="img1" src="../assets/image/radiationSystem/image4.png" alt="">
         <img class="img2" src="../assets/image/radiationSystem/image5.png" alt="">
        </div>
    </div>
    <!-- 毛细管发展史 -->
    <div class="box3" style="overflow: hidden">
        <div class="left-title">HISTORY</div>
        <div class="right-title">HISTORY</div>
        <div class="lineleft"></div>
        <div class="title3" data-aos="fade-up">毛细管发展史</div>
        <div class="lineright"></div>
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
        <div class="line4"></div>
        <div class="line5"></div>
        <div class="line6"></div>
        <div class="line7"></div>
        <div class="line8"></div>
        <div class="line9"></div>
        <div class="line10"></div>
        <div class="line11"></div>
        <div class="line12"></div>
        <div class="line13"></div>
        <div class="line14"></div>
        <div class="line15"></div>
        <div class="line16"></div>
        <div class="line17"></div>
        <div class="line18"></div>
<div class="time">
    <p style="margin-bottom: 79px;">1981</p>
    <p style="margin-bottom: 79px;">1983</p>
    <p style="margin-bottom: 79px;">1996</p>
    <p style="margin-bottom: 79px;">1998</p>
    <p style="margin-bottom: 84px;">2003</p>
    <p style="margin-bottom: 79px;">2007</p>
    <p style="margin-bottom: 77px;">2010</p>
    <p style="margin-bottom: 77px;">2012</p>
    <p>2015</p>
</div>
        <div class="trangle"></div>
        <div class="trangle1"></div>
        <div class="trangle2"></div>
        <div class="trangle3"></div>
        <div class="trangle4"></div>
        <div class="trangle5"></div>
         <div class="trangle6"></div>
          <div class="trangle7"></div>
           <div class="trangle8"></div>
           <div class="infoLeft">
            <p class="p1" style="margin-bottom:225px;">研制出了第一个毛细管网注射成型模具</p>
            <p class="p1" style="margin-bottom:217px;">新一代毛细管网焊接成型机</p>
            <p class="p1" style="margin-bottom:224px;">国内锋尚国际楼盘开始应用毛细管网</p>
            <p class="p1" style="margin-bottom:218px;">运用于家用别墅领域</p>
            <p class="p1">MH享家进入别墅领域,定制舒适智能解决方案</p>
           </div>
           <div class="infoRight">
            <p class="p1" style="margin-bottom:153px;width: 434px">为了1987年的世界建筑博览会而兴建的五幢节能屋在西柏林落成。五幢节能屋分别安装应用了当时最先进的五种新型节能技术设备，进行节能效果比拼。应用了毛细管网地暖系统的节能屋最终在比赛中胜出。
</p>
            <p class="p1" style="margin-bottom:198px;width: 260px;">研发出OPTIMAT SB 20型毛细管网，专为易损坏的恶劣施工条件所研发
</p>
            <p class="p1" style="margin-bottom:224px;">大面积运用于商用住宅领域，如朗诗国际
</p>
            <p class="p1" style="margin-bottom:218px;width: 455px;">第一批户式化三恒系统项目落户交付。伴随着金茂府、玲珑屿、绿郡项目的交付，户式化毛细管辐射也逐渐成熟
</p>
           
           </div>
    </div>
    <!-- 系统优势 -->
    <div class="box4" style="overflow: hidden">
        <div class="info4">
            <div class="pic1">
            <img class="img3" src="../assets/image/radiationSystem/image6.png" alt="">
            <p class="i1">无噪音</p>
            </div>
         <div class="pic1">
        <img class="img7" src="../assets/image/radiationSystem/image10.png" alt="">
       <p class="i3">装饰美观</p>
       </div>
       <div class="pic1">
        <p class="i2">温度均匀</p>
         <img class="img6" src="../assets/image/radiationSystem/image9.png" alt="">
         </div>
         
         <div class="pic1">
         <img class="img4" src="../assets/image/radiationSystem/image7.png" alt="">
         <p class="i5">节能环保</p>
         </div>
<div class="pic1">
         <p class="i4">使用寿命长</p>
         <img class="img8" src="../assets/image/radiationSystem/image11.png" alt=""></div>
<div class="pic1">
         <p class="i6">独立温控</p>
  <img class="img5" src="../assets/image/radiationSystem/image8.png" alt="">
  </div>
         <div class="pic1">
         <img class="img9" src="../assets/image/radiationSystem/image12.png" alt="">
         <p class="i7">室内清洁</p></div>

        </div>
    </div>
     <footPage />
    </div>
   
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.pic1:hover {
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .img9 {
    width: 36%;
    transform: translate(-28px, -40px);
    z-index: 99999;
  }
  .i7 {
    font-size: 40px;
    z-index: 999999;
    transform: (translate(-21px, -29px));
  }
}
.pic1:hover {
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .img6 {
    width: 27%;
    transform: translate(-28px, -50px);
    z-index: 99999;
  }
  .i2 {
    font-size: 40px;
    z-index: 999999;
    transform: (translate(-21px, -29px));
  }
}
.pic1:hover {
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .img3 {
    width: 36%;
    transform: translate(-28px, -40px);
    z-index: 99999;
  }
  .i1 {
    font-size: 40px;
    z-index: 999999;
    transform: (translate(-21px, -29px));
  }
}
//节能环保
.pic1:hover {
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .img4 {
    width: 17%;
    transform: translate(-28px, -40px);
    z-index: 99999;
  }
  .i5 {
    font-size: 35px;
    z-index: 999999;
    transform: (translate(-21px, -29px));
  }
}
//独立温控
.pic1:hover {
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .img5 {
    width: 17%;
    transform: translate(-28px, -40px);
    z-index: 99999;
  }
  .i6 {
    font-size: 35px;
    z-index: 999999;
    transform: (translate(-21px, -29px));
  }
}
//装饰美观
.pic1:hover {
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .img7 {
    width: 17%;
    transform: translate(-28px, -40px);
    z-index: 99999;
  }
  .i3 {
    font-size: 35px;
    z-index: 999999;
    transform: (translate(-21px, -29px));
  }
}
//使用寿命长
.pic1:hover {
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .img8 {
    width: 17%;
    transform: translate(-28px, -40px);
    z-index: 99999;
  }
  .i4 {
    font-size: 35px;
    z-index: 999999;
    transform: (translate(-21px, -29px));
  }
}
.infoLeft {
  position: absolute;
  left: 359px;
  top: 215px;
  text-align: right;
}
.infoRight {
  position: absolute;
  text-align: left;
  top: 335px;
  right: 212px;
}
.p1 {
  font-family: "SourceHanSansCN-Light";
  color: #000;
  font-size: 16px;
}

.trangle {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 8px solid #d0ac32;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 819px;
  bottom: 166px;
}
.trangle1 {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 8px solid #d0ac32;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 686px;
  bottom: 291px;
}
.trangle2 {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 8px solid #d0ac32;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 820px;
  bottom: 413px;
}
.trangle3 {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 8px solid #d0ac32;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 688px;
  bottom: 535px;
}
.trangle4 {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 8px solid #d0ac32;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 821px;
  bottom: 660px;
}
.trangle5 {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 8px solid #d0ac32;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 685px;
  bottom: 783px;
}
.trangle6 {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 8px solid #d0ac32;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 819px;
  bottom: 904px;
}
.trangle7 {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 8px solid #d0ac32;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 685px;
  bottom: 1033px;
}
.trangle8 {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 8px solid #d0ac32;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 822px;
  bottom: 1151px;
}
.time {
  margin-top: 210px;
}
p {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 29px;
}
.line1 {
  background-color: #d0ac32;
  position: absolute;
  height: 2px;
  left: 599px;
  top: 192px;
  width: 227px;
}
.line2 {
  background-color: #d0ac32;
  position: absolute;
  height: 2px;
  left: 691px;
  top: 314px;
  width: 227px;
}
.line3 {
  background-color: #d0ac32;
  position: absolute;
  height: 2px;
  left: 598px;
  top: 437px;
  width: 227px;
}
.line4 {
  background-color: #d0ac32;
  position: absolute;
  height: 2px;
  top: 560px;
  left: 690px;
  width: 227px;
}
.line5 {
  background-color: #d0ac32;
  position: absolute;
  height: 2px;
  left: 599px;
  top: 682px;
  width: 227px;
}
.line6 {
  background-color: #d0ac32;
  position: absolute;
  height: 2px;
  left: 692px;
  top: 806px;
  width: 227px;
}
.line7 {
  background-color: #d0ac32;
  position: absolute;
  height: 2px;
  left: 599px;
  top: 929px;
  width: 227px;
}
.line8 {
  background-color: #d0ac32;
  position: absolute;
  height: 2px;
  left: 690px;
  top: 1052px;
  width: 227px;
}
.line9 {
  background-color: #d0ac32;
  position: absolute;
  height: 2px;
  left: 598px;
  top: 1175px;
  width: 227px;
}
.line10 {
  background-color: #d0ac32;
  position: absolute;
  height: 54px;
  width: 2px;
  left: 825px;
  top: 192px;
}
.line11 {
  background-color: #d0ac32;
  position: absolute;
  height: 54px;
  width: 2px;
  left: 689px;
  top: 313px;
}
.line12 {
  background-color: #d0ac32;
  position: absolute;
  height: 54px;
  width: 2px;
  left: 823px;
  top: 439px;
}
.line13 {
  background-color: #d0ac32;
  position: absolute;
  height: 54px;
  width: 2px;
  left: 688px;
  top: 560px;
}
.line14 {
  background-color: #d0ac32;
  position: absolute;
  height: 54px;
  width: 2px;
  left: 691px;
  top: 808px;
}
.line15 {
  background-color: #d0ac32;
  position: absolute;
  height: 54px;
  width: 2px;
  left: 690px;
  top: 1052px;
}
.line16 {
  background-color: #d0ac32;
  position: absolute;
  height: 54px;
  width: 2px;
  left: 825px;
  top: 683px;
}
.line17 {
  background-color: #d0ac32;
  position: absolute;
  height: 54px;
  width: 2px;
  left: 824px;
  top: 930px;
}
.line18 {
  background-color: #d0ac32;
  position: absolute;
  height: 54px;
  width: 2px;
  left: 824px;
  top: 1177px;
}
.box1 {
  background-image: url(../assets/image/radiationSystem/image3.png);
  background-size: cover;
  height: 797px;
  background-position-y: -2px;
}
.info {
  margin-top: 135px;
}
.title1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 65px;
  color: #d0ac32;
}
.box2 {
  background-image: url(../assets/image/radiationSystem/image1.png); // 背景图片的路径
  height: 550px;
  background-size: 100%;
  background-position-y: -43px;

  position: relative;
  .box2::after {
    // div是你需要添加背景图片的盒子
    content: "";

    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.box3 {
  background-color: #fff;
  height: 1405px;
  position: relative;
}
.info2 {
  text-align: left;
}
.title2 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 51px;
  margin-top: 47px;
  margin-left: 131px;
}
.line {
  background-color: #d0ac32;
  margin-left: 134px;
  height: 3px;
  margin-top: 21px;
  width: 76px;
}
.title2-3 {
  font-family: "SourceHanSansCN-Light";
  color: #515151;
  font-size: 16px;
  width: 510px;
  margin-top: 286px;
  margin-left: 133px;
}
.title2-4 {
  font-family: "SourceHanSansCN-Light";
  color: #515151;
  font-size: 16px;
  margin-top: -451px;
  margin-left: 835px;
  width: 531px;
}
.img1 {
  position: absolute;
  width: 26%;
  left: 110px;
  top: 230px;
}
.img2 {
  position: absolute;
  width: 39%;
  left: 817px;
  top: 230px;
}
.box4 {
  background-image: url(../assets/image/radiationSystem/image2.png);
  width: 100%;
  height: 778px;
  position: relative;
}
.img3 {
  width: 28%;
  position: absolute;
  left: 141px;
  top: 205px;
}
.img4 {
  width: 13.5%;
  position: absolute;
  top: 402px;
  left: 139px;
}
.img5 {
  width: 13.5%;
  position: absolute;
  top: 401px;
  left: 364px;
}
.img6 {
  width: 22.5%;
  position: absolute;
  top: 207px;
  left: 590px;
}
.img7 {
  width: 13.5%;
  position: absolute;
  top: 205px;
  left: 953px;
}
.img8 {
  width: 13.5%;
  position: absolute;
  top: 205px;
  left: 1179px;
}
.img9 {
  width: 28.5%;
  position: absolute;
  top: 510px;
  left: 952px;
}
.i1 {
  font-family: "SourceHanSansCN-Medium";
  position: absolute;
  font-size: 28px;
  left: 158px;
  top: 221px;
  color: #b59e7f;
  height: 161px;
  width: 394px;
  text-align: left;
  display: block;
}
.i5 {
  font-family: "SourceHanSansCN-Medium";
  position: absolute;
  font-size: 28px;
  left: 161px;
  top: 419px;
  color: #96ad49;
}
.i6 {
  font-family: "SourceHanSansCN-Medium";
  position: absolute;
  font-size: 28px;
  left: 388px;
  top: 419px;
  color: #af9e7e;
  z-index: 99;
}
.i7 {
  font-family: "SourceHanSansCN-Medium";
  position: absolute;
  font-size: 28px;
  left: 977px;
  top: 534px;
  color: #92b939;
}
.i2 {
  font-family: "SourceHanSansCN-Medium";
  position: absolute;
  font-size: 28px;
  left: 614px;
  top: 221px;
  z-index: 999;
  color: #757172;
}
.i3 {
  font-family: "SourceHanSansCN-Medium";
  position: absolute;
  font-size: 28px;
  left: 979px;
  top: 221px;
  z-index: 999;
  color: #70534d;
}
.i4 {
  font-family: "SourceHanSansCN-Medium";
  position: absolute;
  font-size: 28px;
  left: 1197px;
  top: 221px;
  z-index: 999;
  color: #3943a5;
}
.left-title {
  font-family: "SourceHanSansCN-Medium";
  font-size: 189px;
  transform: rotate(90deg);
  position: absolute;
  left: -340px;
  top: 274px;
  color: #d0cecf;
}
.right-title {
  font-family: "SourceHanSansCN-Medium";
  font-size: 189px;
  transform: rotate(270deg);
  position: absolute;
  right: -351px;
  top: 731px;
  color: #d0cecf;
}
.lineleft {
  position: absolute;
  height: 2px;
  width: 50px;
  color: black;
  top: 74px;
  z-index: 999;
  left: 499px;
}
.lineright {
  position: absolute;
  height: 2px;
  width: 50px;
  color: black;
  top: 74px;
  z-index: 999;
  right: 499px;
}
.title3 {
  font-family: "SourceHanSansCN-Medium";
  color: black;
  font-size: 51px;
  position: absolute;
  left: 600px;
  top: 21px;
}
</style>